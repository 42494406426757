import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";
import { StyledLink } from "../../components/StyledLink";
import { Link as GatsbyLink } from "gatsby";

const PrivacyCookiePage: React.FC = () => {
  const handleTogglePrefCenter = () => {
    if (typeof window !== "undefined") {
      if (window.OneTrust?.ToggleInfoDisplay) {
        window.OneTrust.ToggleInfoDisplay();
      }
    }
  };

  return (
    <Layout>
      <Head title="Privacy and cookies" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack spacing={4} direction={"column"}>
            <Heading
              as={"h1"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              textAlign={"left"}
              fontFamily={"body"}
              color={"accent.01"}
            >
              Privacy e Cookies
            </Heading>

            <Text>
              HCBE ha nominato un Data Protection Officer, per qualsiasi
              questione inerente il trattamento dei dati da parte del titolare,
              raggiungibile all’indirizzo fisico del titolare e all’indirizzo
              email <em>dpo@it.hcs.com</em>.
            </Text>

            <Text>
              Vuoi modificare le tue preferenze o revocare i consensi sui
              cookies?{" "}
              <StyledLink onClick={handleTogglePrefCenter}>
                Clicca qui
              </StyledLink>
              .
            </Text>

            <Accordion>
              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Informative Privacy e Cookies
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        color="accent.01"
                        as={GatsbyLink}
                        to={"/privacy-cookies/informativa-cookies"}
                      >
                        Informativa cookie
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/privacy-cookies/informative-privacy-cookies/informativa-ai-sensi-degli-art-13-e-14-del-GDPR-richiesta-di-finanziamento.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informativa richiesta finanziamento (PDF)
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/privacy-cookies/informative-privacy-cookies/informativa-privacy-contatto-front-end.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informativa front end (PDF)
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/privacy-cookies/informative-privacy-cookies/informativa-specifica-sul-trattamento-dei-dati-personali-effettuato.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informativa SIC (PDF)
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/privacy-cookies/informative-privacy-cookies/informativa-contratti-hyundai-HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informativa contratto Hyundai (PDF)
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/privacy-cookies/informative-privacy-cookies/informativa-contratti-KIA-HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informativa contratto KIA (PDF)
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/privacy-cookies/informative-privacy-cookies/comunicazione-internet-gdpr.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Comunicazione GDPR (PDF)
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Diritti degli interessati
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Stack spacing={4} direction={"column"} textAlign={"justify"}>
                    <Text>Gentile Cliente,</Text>
                    <Text>
                      il nuovo Regolamento Europeo in materia di protezione dei
                      dati personali (2016/679/UE), detto anche GDPR, Le
                      attribuisce una pluralità di diritti riguardanti il
                      trattamento dei Suoi dati personali da parte di HCBE.
                    </Text>
                    <Text>
                      In particolare, Lei ha il diritto di ottenere in qualunque
                      momento la conferma dell'esistenza o meno dei dati
                      utilizzati dalla Società e di conoscerne il contenuto e
                      l'origine, di verificarne l'esattezza, chiederne
                      l'integrazione, oppure la rettifica. Inoltre, Le è
                      riconosciuto il diritto di chiedere la cancellazione, la
                      trasformazione in forma anonima o il blocco dei dati
                      trattati in violazione di legge, il diritto di ottenere i
                      dati trattati da HCBE nonché il diritto di opporsi in ogni
                      caso, per motivi legittimi, al loro trattamento.
                    </Text>
                    <Text>
                      Nella presente sezione, Le forniamo il dettaglio e la
                      descrizione dei Suoi diritti, il modulo con cui
                      esercitarli e i riferimenti del Responsabile alla
                      Protezione dei Dati (DPO) che ha il ruolo di assistere
                      HCBE nel garantire la tutela dei Suoi dati personali e nel
                      facilitare la gestione dei Suoi diritti.
                    </Text>
                    <Text as="strong">
                      Al fine di consentirci di gestire la Sua richiesta, La
                      preghiamo di utilizzare e compilare in ogni sua parte in
                      modo leggibile il "Modulo Diritti dell’interessato",
                      allegando copia di un documento di riconoscimento in corso
                      di validità. In mancanza dei dati indispensabili per poter
                      trattare la Sua richiesta nonché di tale documentazione,
                      la richiesta è improcedibile.
                    </Text>
                    <Text>
                      <b>Diritto di accesso</b>: è il diritto di richiedere
                      informazioni generali sul trattamento dei propri dati
                      personali, comprese quelle relative alla finalità del
                      trattamento, le categoria dei dati in questione, i
                      destinatari o le categorie di destinatari a cui i dati
                      personali sono stati o saranno comunicati, in particolare
                      se destinatari di paesi terzi o organizzazioni
                      internazionali, la data retention, la loro origine, il
                      diritto di opporre reclamo a un’autorità di controllo,
                      l’esistenza di un processo decisionale automatizzato (es.
                      profilazione);
                    </Text>
                    <Text>
                      <b>Diritto di rettifica</b>: è il diritto di richiedere un
                      aggiornamento, una rettifica o un’integrazione dei propri
                      dati trattati;
                    </Text>
                    <Text>
                      <b>Diritto alla cancellazione</b>: è il diritto di
                      richiedere la cancellazione dei propri dati;
                    </Text>
                    <Text>
                      <b>Diritto di limitazione di trattamento</b>: è il diritto
                      di richiedere la limitazione del trattamento in
                      alternativa alla cancellazione;
                    </Text>
                    <Text>
                      <b>Diritto alla portabilità dei dati</b>: è il diritto di
                      richiedere la trasmissione dei propri dati a un altro
                      Titolare del Trattamento;
                    </Text>
                    <Text>
                      <b>Diritto di opposizione</b>: è il diritto di negare il
                      consenso al trattamento dei propri dati, oppure di opporsi
                      a particolari trattamenti per i quali, ad esempio, aveva
                      invece precedentemente manifestato il proprio consenso;
                      Reclamo: qualora la Sua richiesta non sia classificabile
                      in nessuna delle tipologie sopra riportate.
                    </Text>

                    <UnorderedList pl={5}>
                      <ListItem>
                        <Link
                          href="/documents/privacy-cookies/diritti-degli-interessati/modulo-diritti-degli-interessati.pdf"
                          color="accent.01"
                          isExternal
                        >
                          PDF - Modulo diritti dell'interessato
                        </Link>
                      </ListItem>
                    </UnorderedList>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default PrivacyCookiePage;
